<template>
  <div v-loading="loading" class="home">
    <!-- 快捷入口 -->
    <article class="quick-entry">
      <section v-for="quickEntry in quickEntryList" :key="quickEntry.id" class="quick-entry-item"
               :style="{ background: quickEntry.bgColor }" @click="quickJump(quickEntry.key)">
        <img class="quickEntry-bg" :src="quickEntry.bgImg" alt="">
        <div class="quickEntry-text-box">
          <div class="item-name">{{ quickEntry.name }}</div>
          <div class="item-remark">{{ quickEntry.remark }}</div>
        </div>

      </section>
    </article>

    <article class="assign">
      <header class="header">
        <div>布置作业</div>
      </header>
      <section class="module">
        <div class="module-item" v-for="(item, index) in moduleList" :key="item.id" @click="assignWork(item, index)">
          <div class="module-item-icon">
            <img :src="item.mainPhoto" alt="icon">
          </div>
          <div>{{ item.name }}</div>
        </div>
      </section>
    </article>

    <article class="examine">
      <header class="header">
        <div>检查作业</div>
      </header>
      <section class="section">
        <div class="work-item" v-for="(item, index) in workList" :key="item.id" @click="bagWorkList(index)">
          <div class="work-item-main">
            <div class="date">{{ item.createAt | trimT }}</div>
            <div class="name">{{ item.name }}</div>
            <div class="num">{{ item.itemNum }}个子作业</div>
            <div :class="['status', item.status === '0' ? 'col-309af2' : item.status === '1' ? 'col-1DC060' : '']">
              {{ item.status === '0' ? '未开始' : item.status === '1' ? '进行中' : '已结束' }}
            </div>
            <div class="clazz">{{ item.clazzName }}</div>
            <div class="icon"></div>
          </div>
        </div>
      </section>
      <div class="examine-footer" @click="getMore">
        <div class="footer-more">
          <span>更多作业</span>
          <img src="../assets/z-2.png" alt="top">
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { getClassesList } from "../api/classes";
import { getUUID } from "@/utils/local";
export default {
  name: 'home',
  data() {
    return {
      teacherStatus: 1,
      loading: true,
      carouselPicList: [],
      moduleList: [],
      workList: [],
      quickEntryList: [
        { id: 1, key: 'ai', name: 'AI课堂', remark: '数字资源教学平台', bgColor: '#1976FF', bgImg: require('@/assets/bgImg/bg-1.png') },
        { id: 2, key: 'official', name: '机房考试', remark: '英语听说模拟考试', bgColor: '#1DC060', bgImg: require('@/assets/bgImg/bg-2.png') },
        { id: 3, key: 'exam', name: '线上模考', remark: '突破考场与设备限制', bgColor: '#C92FF5', bgImg: require('@/assets/bgImg/bg-3.png') },
        { id: 4, key: 'work', name: '作业报告', remark: '阶段性作业数据统计', bgColor: '#FFB032', bgImg: require('@/assets/bgImg/bg-4.png') },
      ]
    }
  },
  created() {
    this.getSlidePic()
    this.teacherStatus = sessionStorage.getItem('teacherStatus')
    getClassesList().then(res => {
      const { data } = res
      this.$store.commit('haveClassChange', data && data.length > 0)
    })
  },
  activated() {
    this.findBagList()
  },
  methods: {
    quickJump(key) {
      switch (key) {
        case 'ai':
          this.toAI()
          break
        case 'official':
          this.roomExam()
          break
        case 'exam':
          this.assignExam()
          break
        case 'work':
          this.workReport()
          break
      }
    },
    getSlidePic() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getSlideShowList,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          location: 6
        }
      }).then((response) => {
        if (response.state === '11') {
          this.carouselPicList = [...response.data]
          this.getOnlyIndexModule()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    getOnlyIndexModule() {
      const data = {
        channelKey: 'channel-ZUOY',
        gradeId: sessionStorage.getItem('gradeTypeId'),
        provinceId: sessionStorage.getItem('provinceId'),
        cityId: sessionStorage.getItem('cityId'),
        schoolId: sessionStorage.getItem('schoolId')
      }
      this.$axios({
        method: 'post',
        url: this.$urls.getFirstLevelModuleByChannel,
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.state === '11') {
          const { data } = response
          this.moduleList = data
          this.findBagList()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    findBagList() {
      this.$axios({
        method: 'post',
        url: this.$urls.findBagList,
        data: {
          pageIndex: 1,
          pageSize: 10
        }
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.loading = false
          this.workList = [...response.data]
          this.$store.commit('includeAdd', 'home')
        }
      }).catch(() => {
        this.loading = false
      })
    },
    toAI() {
      let udid = getUUID()
      let teacherId = this.$store.state.userInfo.id || ''
      let loginAuthToken = sessionStorage.getItem('sessionId')
      udid = encodeURIComponent(udid)
      teacherId = encodeURIComponent(teacherId)
      loginAuthToken = encodeURIComponent(loginAuthToken)
      let url = `${process.env.VUE_APP_AI_PATH}/#/silent-login?teacherId=${teacherId}&loginAuthToken=${loginAuthToken}&loginUdid=${udid}`
      window.open(url, '_blank')
    },
    roomExam() {
      let superAdmin = sessionStorage.getItem('superAdmin')
      if (superAdmin === '1') {
        this.$router.push({
          name: 'officialList'
        })
      } else if (superAdmin === '2') {
        this.$router.push({
          name: 'officialExam'
        })
      }
    },
    assignExam() {
      this.$router.push({
        name: 'checkExam'
      })
    },
    workReport() {
      this.$router.push({
        name: 'workReport'
      })
    },
    assignWork(item, index) {
      this.$store.commit('includeDel', 'assignWork')
      this.$router.push({
        name: 'assignWork',
        query: {
          id: item.id
        }
      })
    },
    getMore() {
      this.$router.push({
        name: 'checkWork'
      })
    },
    bagWorkList(index) {
      const { workList } = this
      const { id } = workList[index]
      const { name } = workList[index]
      this.$router.push({
        name: 'bagWorkList',
        query: {
          id,
          name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home{
  padding-bottom: 40px;
  article{
    border-radius: 16px;
    background-color: #fff;
    margin-top: 20px;
    &.quick-entry {
      @include flex(flex-start);
      background-color: #fff;
      padding: 0 20px;
      margin-bottom: 10px;
      margin-top: 20px;
      border-radius: 16px;

      .quick-entry-item {
        height: 175px;
        width: 265px;
        position: relative;
        .quickEntry-bg {
          width: 265px;
          height: 175px;
        }

        .quickEntry-text-box {
          position: absolute;
          top: 53px;
          left: 36px;
          color: #FFFFFF;
          cursor: pointer;
        }


        .item-name {
          font-size: 26px;
          line-height: 37px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .item-remark {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    &.assign {
      padding-bottom: 20px;
      .module {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;

        .module-item {
          width: 198px;
          height: 68px;
          padding: 12px 20px;
          margin: 7px;
          font-size: 16px;
          border: 1px solid #E5E5E5;
          border-radius: 16px;
          cursor: pointer;
          transition: all .3s;
          @include flex(flex-start);

          &:hover {
            box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .08);
            transform: translateY(-4px);
            color: #000000;
          }

          .module-item-icon {
            width: 42px;
            height: 42px;
            margin-right: 14px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .header {
      @include flex(space-between);
      height: 76px;
      @include font(20px);
      line-height: 28px;
      font-weight: bold;
      padding: 28px 28px 13px;
    }

    .section {
      padding: 0 28px;

      .work-item {
        padding: 0 28px;
        border-radius: 8px;
        cursor: pointer;
        transition: box-shadow .3s;

        &:hover {
          box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .08);
        }
      }

      .work-item-main {
        @include flex(space-between);
        height: 80px;
        border-bottom: 1px solid #F1F1F1;
        text-align: center;

        div {
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .date {
          width: 180px;
          text-align: left;
        }

        .name {
          width: 396px;
        }

        .num {
          width: 146px;
        }

        .status {
          width: 146px;
          color: #999999;
        }

        .clazz {
          width: 146px;
        }

        .icon {
          @include icon(url("../assets/home/arrow.png"), 60px, 80px);
          background-size: 6px 11px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    .examine-footer {
      padding: 20px 0;
      text-align: center;

      .footer-more {
        color: #333333;
        font-size: 16px;
        height: 48px;
        line-height: 48px;
        width: 250px;
        margin: 0 auto;
        border-radius: 24px;

        &:hover {
          background-color: #F6F6F6;
        }

        img {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
